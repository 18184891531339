// import Vue from 'vue'
import baseContextComponent from "@/components/baseContextComponent";
import helpers from "@/helpers";
import { Component } from "vue-property-decorator";

@Component({})
export default class homePage extends baseContextComponent {
	title: string = "Benvenuto in";
	subtitle: string = "My Webins";

	mounted() {
		var self = this;
	}

}
